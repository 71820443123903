import { memo, useEffect, useState } from "react";
import data from "./data.json";

const Entry = memo(({ data }) => {
  if (Math.random() > 0.5) {
    const reverse = Math.random() > 0.5;
    return (
      <div className={"flex w-screen " + (reverse && "flex-row-reverse")}>
        <div className="">
          <div
            className={
              "hold border-4 p-1 py-4 up border-b-0 h-[310px] sm:h-[560px]  overflow-hidden " +
              (1 && "col-span-2 ") +
              (reverse ? "border-l-0" : "border-r-0")
            }
          >
            <p
              className={
                "manuka uppercase origin-left  whitespace-nowrap vert-marq " +
                (Math.random() > 0.5 && "op")
              }
            >
              <span class="m-0 p-0 pb-2 sm:pb-4" aria-hidden="true">
                {data[0]}
              </span>
              <span class="m-0 p-0 pb-2 sm:pb-4" aria-hidden="true">
                {data[0]}
              </span>
              <span class="m-0 p-0" aria-hidden="true">
                {data[0]}
              </span>
            </p>
          </div>
        </div>
        <div className="flex-1 flex flex-col min-w-0 ">
          {Array.from(Array(5), (e, x) => (
            <div
              className={
                "hold border-4 p-1 px-2 sm:px-4  border-b-0 w-full   " +
                (1 && "col-span-2")
              }
            >
              <div className="manuka overflow-clip max-w-full  marquee uppercase origin-left whitespace-nowrap ">
                <div
                  className={"marquee__inner " + (Math.random() > 0.5 && "op")}
                >
                  <div>
                    <span className="pr-2 sm:pr-4">{data[x + 1]}</span>
                    <span className="pr-2 sm:pr-4">{data[x + 1]}</span>
                    <span className="pr-2 sm:pr-4">{data[x + 1]}</span>
                    <span className="pr-2 sm:pr-4">{data[x + 1]}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div className="grid md:grid-cols-2 w-screen">
        {Array.from(Array(6), (e, x) => (
          <div
            className={
              "hold border-4 p-1 px-2 sm:px-4  border-b-0 min-w-0   " +
              ((x === 0 || x === 5) && "col-span-2 ") +
              ((x === 1 || x === 3) && " border-r-0")
            }
          >
            <div className="manuka overflow-clip max-w-full  marquee uppercase origin-left whitespace-nowrap ">
              <div
                className={"marquee__inner " + (Math.random() > 0.5 && "op")}
              >
                <div>
                  <span className="pr-2 sm:pr-4">{data[x]}</span>
                  <span className="pr-2 sm:pr-4">{data[x]}</span>
                  <span className="pr-2 sm:pr-4">{data[x]}</span>
                  <span className="pr-2 sm:pr-4">{data[x]}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }
});

function App() {
  const entries = data
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);

  const [list, setList] = useState([0, 1, 2, 3, 4]);

  function onScroll() {
    if (
      document.documentElement.scrollHeight -
        window.innerHeight -
        document.documentElement.scrollTop <
      250
    ) {
      setList((list) => [...list, list.length]);
    }
  }

  useEffect(() => {
    if (window !== undefined) {
      // clean up code
      window.removeEventListener("scroll", onScroll);
      window.addEventListener("scroll", onScroll, { passive: true });

      return () => {
        window.removeEventListener("scroll", onScroll);
      };
    }
  }, []);

  return (
    <div
      id="main"
      className="text-[50px] leading-[50px] sm:text-[100px] sm:leading-[100px] cursor-default"
    >
      {list.map((ind) => (
        <Entry
          key={ind}
          data={entries.splice(ind % 10224, (ind + 6) % 10224)}
        />
      ))}
    </div>
  );
}

export default App;
